import React from 'react'
import Card from './Card'
import planing from './img/Planning.png'
import Designing from './img/Designing.png'
import Defining from './img/Defining.png'
import Coding from './img/coding.png'
import Testing from './img/Testing.png'
import Implementation from './img/implementation.png'
import Maintenance from './img/maintenance.png'

export default function Steps() {
  return (
    <>
        <div className='container'>
            <div className='row'>
                <div className='col-md-3'>
                    <Card img={planing} title={"Planning"} desc={"We work closely with you to understand your goals and to develop a custom strategy to develop the best possible app."}/>
                </div>
                <div className='col-md-3'>
                    <Card img={Designing} title={"Designing"} desc={"Design phase brings your vision to life, with a focus on attention to detail, aligning your brand and business goals, to user experience for your app."}/>
                </div>
                <div className='col-md-3'>
                    <Card img={Defining} title={"Defining"} desc={"In the definition phase, we refine the finer details of the key features and functionalities of the app, optimising the flow and UX, setting the foundation for a successful development process."}/>
                </div>
                <div className='col-md-3'>
                    <Card img={Coding} title={"Coding"} desc={"This is where the magic happens. Our development team delivers high-quality, efficient, and scalable code using the latest techniques, languages, and methodologies."}/>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-3'>
                    <Card img={Testing} title={"Testing"} desc={"We leave no stones unturned ensure that the app is of highest quality. Apps go through rigorous testing to deliver an error-free experience."}/>
                </div>
                <div className='col-md-3'>
                    <Card img={Implementation} title={"Implementation"} desc={"We deploy the final app and conduct a thorough review to ensure that every aspect of the app is functioning smoothly, using continuous integration and deployment methodologies."}/>
                </div>
                <div className='col-md-3'>
                    <Card img={Maintenance} title={"Maintenance"} desc={"Our team proactively monitors and maintains your app’s performance, ensuring it stays up-to-date and optimised for the latest technologies."}/>
                </div>
            </div>
        </div>
    </>
  )
}
