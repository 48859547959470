import "./App.css";
import { Navbar, Nav, Container, Row } from "react-bootstrap";
import logo from "./img/logo.png";
import Slider from "./Slider";
import MainHeading from "./MainHeading";
import Steps from "./Steps";
import Services from "./Services";
import Technologies from "./Technologies";
import Contact from "./Contact";
import Footer from "./Footer";

function App() {
  let width = window.innerWidth;
  return (
    <>
      <Navbar bg="light" variant="light" expand="lg" id={'home'}>
        <Container>
          <Navbar.Brand href="#home" className={width < 500 ? "w-80" : ""}>
            <img className="w-80" src={logo} alt="SLP Technologie" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="mr-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#Process">Process</Nav.Link>
              <Nav.Link href="#Services">Services</Nav.Link>
              <Nav.Link href="#Technologies">Technologies</Nav.Link>
              <Nav.Link href="#Contact">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Slider */}
      <Slider />
      <Container>
        <Row className={`${width < 500 ? "mt-3" : "mt-5"}`}>
          <MainHeading
            title={"The"}
            spanTitle={"SLP Technologie Way"}
            id="Process"
          />
        </Row>
      </Container>
      <Steps />
      <Container>
        <Row className={`row ${width < 500 ? "mt-3" : "mt-5"}`} id="Services">
          <MainHeading title={"What Are We"} spanTitle={"Good At"} />
        </Row>
        <Services/>
        <div className={`row ${width < 500 ? "mt-3" : "mt-5"}`}>
        <MainHeading title={"We Work with"} spanTitle={"Technologies"} id="Technologies"/>
        </div>
        <Technologies/>
        <div className={`row ${width < 500 ? "mt-3" : "mt-5"}`}>
        <MainHeading title={"Get in contact !"} id={"Contact"}/>
        </div>
        <Contact/>
      </Container>
      <Footer/>
    </>
  );
}

export default App;
