"use client";
import React from 'react';
const Card = ({ title, desc, img }) => {
let width = window.innerWidth;
  return (
    <div className={`text-center hover-up-animation ${width > 500 ? "mt-5 p-3" : "mt-0 p-1"}`}>
      <img
      className='step-icon'
        src={img}
        alt={title}
      />
      <p className='sub-heading mt-2 mb-2'>{title}</p>
      <p>{desc}</p>
    </div>
  );
};


export default Card;
